<template>
  <div>
    <SimilarItems v-if="isOutSite" />
    <BestSeller v-if="isOutSite" />
    <QuickRegister :from="'detail'" />
    <SimilarRecommend 
      v-if="WEB_CLIENT == 'shein' && allDataReady && !isOutSite" 
    />
    
    <template v-if="MAIN_BFF_APOLLO.v6Rec">
      <RecommendBottom v-if="allDataReady || delayDestroy" />
    </template>
    <template v-else-if="allDataReady || delayDestroy">
      <template v-if="WEB_CLIENT == 'shein' && combineRec">
        <RecommendCombine
          :recommend-config="recommendConfig"
          :common-recommend-config="commonRecommendConfig"
        />
      </template>
      <RwRecommend v-else-if="WEB_CLIENT == 'romwe'" />
    </template>


    <template v-if="MAIN_BFF_APOLLO?.v2">
      <UsInfo
        :showFindsInfo="is_out_site"
        :onloadStatus="detail_page_onload_status"
      />
    </template>
    <template v-else>
      <UsInfoV1 />
    </template>
    
    <div style="background-color: #f6f6f6;height: 1.3rem;"></div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import lazyComponent from 'public/src/services/lazyComponent'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import recommendExposureListInstance from 'public/src/pages/goods_detail/utils/recommendExposureList.js'
import { CountDown } from '@shein/common-function'
import { getQueryString } from '@shein/common-function'
const { WEB_CLIENT } = gbCommonInfo
daEventCenter.addSubscriber({ modulecode: '1-6-5' })
const components = [
  {
    name: 'QuickRegister',
    style: {  },
    componentFactory: () => import('../../common/quick_register/container.vue')
  },
  {
    name: 'RecommendCombine',
    style: {  },
    componentFactory: () => import('./recommendCombine.vue'),
    emitLoaded: true
  },
  {
    name: 'RwRecommend',
    componentFactory: () => import('./rwRecommendNew.vue'),
    style: {  },
    emitLoaded: true
  },
  {
    name: 'UsInfoV1',
    style: {  },
    componentFactory: () => import('./usInfo')
  },
  {
    name: 'UsInfo',
    style: { },
    componentFactory: () => import('public/src/pages/goods_detail_v2/innerComponents/bottom/innerComponents/UsInfo.vue'),
  },
  {
    name: 'SimilarItems',
    style: {  },
    componentFactory: () => import('./similarItems')
  },
  {
    name: 'BestSeller',
    style: {  },
    componentFactory: () => import('./bestSeller')
  },
  {
    name: 'SimilarRecommend',
    style: {  },
    componentFactory: () => import('./similarRecommend')
  },
]

const componentsBff = [
  {
    name: 'RecommendBottom',
    style: {  },
    componentFactory: () => import('public/src/pages/goods_detail_v2/innerComponents/recommend/RecommendBottom/index.vue')
  },
]

export default {
  name: 'BottomContent',
  components: {
    ...([...components, ...componentsBff].reduce((componentsResult, curr) => {
      componentsResult[curr.name] = lazyComponent({
        ...curr,
        options: {
          style: curr.style || { height: '2rem' }
        }
      })
      return componentsResult
    }, {})),
  },
  data() {
    return {
      combineRec: true,
      WEB_CLIENT,
      recommendConfig: {},
      commonRecommendConfig: {},
      recommendFrame: 0,
      countTimer: new CountDown(),
    }
  },
  computed: {
    ...mapState('newProductDetail/common', ['exposeGoods', 'isOutSite', 'oneClickPayState', 'delayDestroy']),
    ...mapGetters('newProductDetail', ['fsAbt']), // 新数据流
    ...mapGetters('newProductDetail/common', ['detail', 'language', 'allDataReady']),
    // NEW BFF
    ...mapState('productDetail', ['MAIN_BFF_APOLLO']),
    ...mapState('productDetail/common', ['is_out_site', 'detail_page_onload_status']),
  }, 
  watch: {
    oneClickPayState: {
      handler(val) {
        const oneClickPayOverTime = +(val?.billInfo?.oneClickPayOverTime || 0)
        const isSupport = val?.support || false
        if (isSupport && oneClickPayOverTime) {
          this.countTimer.start({
            seconds: oneClickPayOverTime,
            endFunc: () => {
              this.$store.commit('newProductDetail/updateOneClickPayTimeStutas', false)
            }
          })
        }
      },
      immediate: true,
    },
    allDataReady: {
      handler(val) {
        if (val) {
          // 表示从立即购下单页返回商详页，需要下滑至推荐位
          console.log('wwt allDataReady', val)
          const afterbuyroad = getQueryString({ key: 'buyNow' }) === 'afterbuyroad'
          const afterbuyroadAbt = this.fsAbt?.DetailBuynow?.p?.afterbuyroad === 'recommend'
          if(afterbuyroad && afterbuyroadAbt){
            this.$nextTick(() => {
              // 加载底部推荐位
              appEventCenter.$emit('allLazyComponentRender')
            })
            // delete路由参数buyNow
            const url = new URL(window.location)
            url.searchParams.delete('buyNow')
            window.history.replaceState({}, '', url)
            setTimeout(() => {
              this.setGoodsDetailScroll()
            }, 3500)
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.recommendConfig = {
        getExposeTarget: this.getExposeYmalTargetHandle,
      }
      // recommendNew公共推荐项config
      this.commonRecommendConfig = {
        getExposeTarget: (params) => {
          recommendExposureListInstance.pushQueen({
            targets: params?.targets || [],
          })
        }
      }
    })
  },
  deactivated() {
    this.countTimer && this.countTimer.clear()
  },
  beforeDestroy() {
    this.countTimer && this.countTimer.clear()
  },
  methods: {
    ...mapActions('newProductDetail/common', ['setGoodsDetailScroll']),
    ...mapActions('newProductDetail', ['updateRecommendGoods']),
    // 如果合并样式逻辑错误，兜底展示不合并的逻辑
    combineHideEvent(bol) {
      this.combineRec = !bol
    },
    // 获取 ymal 曝光
    async getExposeYmalTargetHandle({ targets }) {
      if (this.exposeGoods.status) {
        const targetsGoods = await this.updateRecommendGoods({ targets, type: 'ymal' })
        recommendExposureListInstance.pushQueen({
          targets: targetsGoods,  
          isDomList: false,
        })
        return
      }
      recommendExposureListInstance.pushQueen({
        targets,
      })
    },
  },
}
</script>

<style lang="less">
.c-detail-section {
    margin-bottom: .22rem;
    background: #fff;
    padding: 0 .32rem;
    // font-size: 0;
    // 移除空白间距
    display: flex;
    flex-wrap: wrap;

    .goodsl-item-wrap {
      margin-bottom: .64rem;
    }

    &__title {
        padding: .32rem 0 .3733rem 0;
        line-height: .51rem;
        color: #333;
        text-transform: capitalize;
        font-weight: bold;
        .font-dpr(28px);
    }

    // &__category {
    //     .clearfix();
    // }
    // &__category-limit {
    //     height: 2.08rem;
    //     overflow: hidden;
    // }

    // &__category-item {
    //     .fl();
    //     height: .72rem;
    //     line-height: .72rem;
    //     margin: 0 .2133rem .32rem 0;
    //     padding: 0 .32rem;
    //     background: #f6f6f6;
    //     .font-dpr(24px);
    //     color: #666;
    //     font-weight: 400;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //     white-space: nowrap;

    //     a {
    //         display: inline-block;
    //         max-width: 86vw;
    //         height: 100%;
    //         text-decoration: none;
    //         white-space: nowrap;
    //         overflow: hidden;
    //         text-overflow: ellipsis;
    //     }

    // }
    // &__category-item_active {
    //     background: #222;
    //     color: #fff;

    //     a {
    //         color: #fff;
    //     }
    // }

    &__drawer-header {
        position: absolute;
        width: 100%;
        height: 1.17rem;
        line-height: 1.17rem;
        background-color: #fff;
        z-index: 2; /* stylelint-disable-line declaration-property-value-blacklist */
    }

    &__drawer-content {
        padding-top: 1.17rem;
        height: 100%;
        box-sizing: border-box;
        overflow-y: auto;
        .goodsl-item-wrap {
          margin-bottom: 0.32rem;
        }
    }

    &__goods-list-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .product-item-ctn {
          margin-bottom: 0.32rem;
        }
    }

    &__product-item-placehold {
        width: 2.978rem;
        height: 4.6933rem;
        background-image: url(@LAZY_IMG);
        background-size: 2.978rem 3.88rem;
        background-repeat: no-repeat;
    }

    &__foot-view-all {
        width: 100%;
        height: 1.0667rem;
        line-height: 1.0667rem;
        .font-dpr(24px);
        font-weight: bold;
        text-align: center;
        color: #666666;
    }
}
.mgds-recommend {
  .mgds-goodsd {

    .detail-recommend-more {
      box-sizing: content-box;
      margin: 0 -.32rem;
      position: absolute;
      left: 0/*rtl:ignore*/;
      right: 0/*rtl:ignore*/;
      bottom: 0;
      height: 1rem;
      line-height: 1rem;
      color: #666;
      text-align: center;
      background: #fff;
      box-shadow: 0px -18px 15px 0px #fff;
      font-weight: bold;
      [class*="iconfont"] { /* stylelint-disable-line */
        display: inline-block;
        .margin-l(.11rem);
        vertical-align: middle;
        color: #999;
        font-weight: normal;
        .font-dpr(32px);
      }
    }
  }
}

.mgds-recommend {
  background-color: #fff;
  margin-bottom: .22rem;
  &__title-switch {
    background-color: #fff;
    .flexbox();
    justify-content: space-around;
    margin-top: .22rem;
    /*rw:begin*/
    position: sticky;
    z-index: @zindex-header;
  }

  &__title-switch-item {
    line-height: 1.2rem;
    color: @sui_color_gray_dark3;
    .font-dpr(22px);
    /* rw:begin */
    font-family: 'Adieu';
    &_active {
      position: relative;
      color: #222;
      /*rw:begin*/
      color: @sui_color_main;
      /*rw:end*/
    }
  }
}
.goodsl-promotion {
  .goodsl-promotion-item {
      display: inline-block;
      max-width: 4.52rem;
      min-height: 0.4267rem;
      line-height: 0.4267rem;
      padding: 0 .11rem;
      text-align: center;
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: @sui_color_promo_bg;
      color: @sui_color_promo_dark;
  }
}
</style>
